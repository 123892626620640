import React from "react"
import {
  Layout,
  FlexRow,
  Parallax,
  NewsletterSection,
} from "@components/layout"
import { BlogCategoryItem } from "@components/blog"
import { Breadcrumbs } from "@components/shared"

const BlogCategories = ({ pageContext }) => {
  const { posts, page, categories } = pageContext
  return (
    <Layout seo={page.seo} siteMetaData={page.siteMetaData}>
      <FlexRow>
        <Parallax height={300} image={page.featuredImage}>
          <h1 dangerouslySetInnerHTML={{ __html: page.title }}></h1>
        </Parallax>
        <Breadcrumbs
          elements={[
            {
              label: "Blog",
              url: `/blog/`,
            },
          ]}
        />

        {categories.map((el, k) => {
          return (
            <BlogCategoryItem
              {...el}
              featuredImage={el.posts.nodes[0].featuredImage}
              key={k}
            />
          )
        })}
      </FlexRow>
      <NewsletterSection />
    </Layout>
  )
}

export default BlogCategories
